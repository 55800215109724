import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { pageService } from './pageService.service';

@Component({
  selector: 'app-side-table',
  templateUrl: './side-table.component.html',
  styleUrls: ['./side-table.component.scss'],
})
export class SideTableComponent implements OnInit {
  @ViewChild('sideTable', { static: true }) sidenav: MatSidenav;

  @Input()
  openNav: boolean;

  constructor(private pageService: pageService) {}

  ngOnInit(): void {
    // this.pageService.setSidenav(this.sidenav);
  }

  close() {
    console.log('Closes ' + this.sidenav);
    this.sidenav.close();
  }
}
