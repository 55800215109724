import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { getEntityList, get_ruleset_list } from 'src/app/services/global-data-urls';
import { ModelNodeService } from '../../model-builder/model-node.service';

@Component({
  selector: 'app-ruleset-finder',
  templateUrl: './ruleset-finder.component.html',
  styleUrls: ['./ruleset-finder.component.scss'],
})
export class RulesetFinderComponent {
  listOfCategories: string[] = [];
  listOfTopics: any[] = [];
  filteredlistOfTopics: any[] = [];
  entitySearchStr: string;
  mocList: any = {};
  topicDetail: any = { Examples: [] };
  custom_rules: any;
  ruleset_selected: any;
  template_index: any;
  tempapp: any;
  editIndex: number;
  isAppbuilder: boolean = true;

  constructor(private apiService: ApiService, private modelnodeService: ModelNodeService) {}
  ngOnInit(): void {
    this.isAppbuilder = JSON.parse(localStorage.getItem('isAppbuilder'));
    this.tempapp = this.getTemplate();
    this.template_index = this.tempapp.templates.findIndex((a) => a.active);
    this.editIndex = this.tempapp.templates[this.template_index].data.length - 1;
    this.apiService
      .getData(`${get_ruleset_list}?user_email=${localStorage.getItem('username')}`)
      .subscribe((response: any) => {
        this.listOfTopics = response.map((data: any) => ({ ...data, name: data['Topic Name'], selected: false }));
        this.modelnodeService.topicList = this.listOfTopics;
        this.filteredlistOfTopics = [...this.listOfTopics];

        // console.log(this.listOfTopics)
      });
  }
  getTemplate() {
    if (this.isAppbuilder) {
      return JSON.parse(localStorage.getItem('appTemplate'));
    } else {
      return JSON.parse(localStorage.getItem('modelTemplate'));
    }
  }

  UpdateTopicLists(topic) {
    topic.selected = true;
    this.topicDetail = this.listOfTopics.filter((data: any) => data.selected == true)[
      this.listOfTopics.filter((data: any) => data.selected == true).length - 1
    ];
    this.custom_rules = this.topicDetail.custom_rules;
  }

  UpdateTopicList(topicdetail) {
    let newrule = JSON.parse(localStorage.getItem('modelTemplate'));

    newrule.templates[this.template_index].data[this.editIndex].rulesUpdate = 'update';
    // newrule.templates[this.template_index].data[this.editIndex].rules = this.listOfTopics.filter((data:any)=>data.selected == true);
    // newrule.templates[this.template_index].data[this.editIndex].rules[0].preset_rules = this.topicDetail.preset_rules;
    newrule.templates[this.template_index].data[this.editIndex].rulesInput = this.topicDetail.custom_rules;
    newrule.templates[this.template_index].data[this.editIndex].rulesetname = this.topicDetail.ruleset_name;
    newrule.templates[this.template_index].data[this.editIndex].rulesetDesc = this.topicDetail.ruleset_description;
    localStorage.setItem('modelTemplate', JSON.stringify(newrule));
  }

  filterTopics() {
    if (this.entitySearchStr) {
      let searchText = this.entitySearchStr.toLocaleLowerCase();
      this.filteredlistOfTopics = this.listOfTopics.filter((it) => {
        return it.ruleset_name.toLocaleLowerCase().includes(searchText);
      });
    } else {
      this.filteredlistOfTopics = [...this.listOfTopics];
    }
  }

  cancel() {
    localStorage.setItem('cancelStatusUpdate', 'cancel');
  }

  // selectAll(event){
  //   this.filteredlistOfTopics = this.filteredlistOfTopics.map((data)=> ({
  //     ...data,
  //     selected : event.checked ? true : false
  //   }));
  //   this.listOfTopics = this.listOfTopics.map((data)=> ({
  //     ...data,
  //     selected : event.checked ? true : false
  //   }));
  // }
}
