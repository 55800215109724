import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
// import * as studentData from './table-data.json';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { pageService } from './pageService.service';
import { ColDef, ColumnApi } from 'ag-grid-community';
import { ApiService } from 'src/app/services/api.service';
import { Router } from '@angular/router';
import { get_db_data, list_df } from 'src/app/services/global-data-urls';

interface Student {
  _id: String;
  index: Number;
  name: String;
  email: String;
  phone: String;
  snippet: String;
  about: String;
}

@Component({
  selector: 'app-select-table',
  templateUrl: './select-table.component.html',
  styleUrls: ['./select-table.component.scss'],
})
export class SelectTableComponent implements OnInit, OnDestroy {
  tableData: string;
  gridOptions = {
    pagination: true,
    paginationPageSize: 10,
  };
  modelDataSource = [];
  columnDefs: ColDef[] = [];
  appDetails: { [k: string]: any };
  app: any = {};
  store_response: any;
  dataf_name: any;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private api: ApiService,
    private pageService: pageService,
  ) {}

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
  }
  modelGridOptions: any;

  ngOnInit(): void {
    console.log(this.tableData);
    this.getAppDetails();
  }
  modelOnGridReady(params) {
    this.gridColumnApi = params.columnApi;
    params?.api?.sizeColumnsToFit();
    this.autoSizeAll(true);
  }

  private gridColumnApi!: ColumnApi;

  autoSizeAll(skipHeader: boolean) {
    // const allColumnIds: string[] = [];
    // this.gridColumnApi.getAllColumns()!.forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });
    // this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    // this.gridColumnApi.api.sizeColumnsToFit();
  }
  getTableData(id, dbName, dfId) {
    // console.log(dfId);
    // console.log(id);
    localStorage.setItem('database_id', id);
    localStorage.setItem('dataflow_id', dfId);
    localStorage.setItem('database_name', dbName);
    let payload = { db_id: id };
    this.api.getData(get_db_data, payload).subscribe(
      (response) => {
        this.modelDataSource = response;
        this.columnDefs = [];
        // for (let column of response.filter(data=> data)) {
        //   this.columnDefs = [
        //     ...this.columnDefs,
        //     {
        //       headerName: column.charAt(0).toUpperCase() + column.slice(1),
        //       field: column,
        //       flex: 1,
        //     },
        //   ];
        // }
        this.columnDefs = [
          {
            field: 'SNIPPET',
            headerName: 'Snippet',
            width: 350,
          },
          {
            field: 'topics',
            headerName: 'Topics',
            width: 350,
          },
          {
            field: 'confidence',
            headerName: 'Confidence',
            width: 350,
          },
        ];
      },
      (error) => {
        console.log(error);
      },
    );
    // this.api.getData(get_db_data, payload).subscribe((response) => {

    // });
  }
  getAppDetails() {
    this.app = JSON.parse(localStorage.getItem('modelTemplate'));

    let payload = {
      email: localStorage.getItem('username'),
      env_name: this.app.env_name,
      // email: 'shiva.muddaliar@accrete.ai',
      // env_name: 'Env2019',
      page_size: 50,
      page_number: 1,
      search_text: '',
      sort_field: 'created_ts',
      sort_order: -1,
    };
    this.api.postData(list_df, payload).subscribe(
      (response) => {
        if (response.data && response.data.length > 0) {
          this.dataf_name = response.data;
          console.log(this.dataf_name);
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  selectedValue(data) {
    this.pageService.sendDataToOtherComponent(data);
    this.tableData = data;
    this.dialogRef.close(data);
  }

  cancel() {
    let data = null;
    this.dialogRef.close(data);
  }
}
