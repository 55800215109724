import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { SidenavService } from '../sidenav/sidenav.service';
import { clear_notification, fetch_notification, read_notification } from 'src/app/services/global-data-urls';
import { ApiService } from 'src/app/services/api.service';
import { MessageService } from 'src/app/services/message.service';
import { ColumnController } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { CustomAlertService } from 'src/app/services/custom-alert.service';

@Component({
  selector: 'app-right-sidenav',
  templateUrl: './right-sidenav.component.html',
  styleUrls: ['./right-sidenav.component.scss'],
})
export class RightSidenavComponent implements OnInit {
  requestBody: any;

  @ViewChild('rightSidenav', { static: true }) sidenav: MatSidenav;

  @Input()
  openNav: boolean;
  notification: any;
  flag: boolean = true;
  constructor(
    private sidenavService: SidenavService,
    private apiService: ApiService,
    private messageService: MessageService,
    public datePipe: DatePipe,
    private customAlertService: CustomAlertService,
  ) {}

  ngOnInit(): void {
    this.requestBody = {
      user_email: localStorage.getItem('username'),
      read_only: false,
      unread_only: true,
    };

    if (this.requestBody.user_email) {
      this.apiService.postData(fetch_notification, this.requestBody).subscribe((response: any) => {
        this.notification = response;
        if (this.notification.length > 0) {
          this.flag = false;
        }
        this.notification.reverse();
      });
    }

    this.sidenavService.setSidenav(this.sidenav);

    this.messageService.subject.subscribe((response) => {
      if (Object.keys(response).length > 0 && response.notification_type == 'REFRESH_CREDS') {
        localStorage.setItem('username', response.email);
        localStorage.setItem('access_token', response.access_token);
        localStorage.setItem('id_token', response.id_token);
      } else if (Object.keys(response).length > 0) {
        this.notification.splice(0, 0, response);
        this.flag = false;
        this.customAlertService.open(response.notification_msg, 'Notification!');
      }
    });
  }

  close() {
    this.sidenav.close();
  }
  readAllNotifications() {
    const readRequestBody = {
      user_email: localStorage.getItem('username'),
      mark_all: true,
    };
    this.apiService.postData(read_notification, readRequestBody).subscribe((response: any) => {});
  }
  readNotifications(id) {
    const readRequestBody = {
      user_email: localStorage.getItem('username'),
      id: id,
    };
    this.apiService.postData(read_notification, readRequestBody).subscribe((response: any) => {});
  }
  clearNotifications() {
    const clearAllRequestBody = {
      user_email: localStorage.getItem('username'),
    };
    this.apiService.postData(clear_notification, clearAllRequestBody).subscribe((response: any) => {});
    this.flag = true;
    this.notification = [];
    this.sidenavService.clearall.next(true);
  }
}
