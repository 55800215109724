<!-- src/app/my-grid-application/my-grid-application.component.html -->
<div class="datagrid">
  <span class="table-header">Component Setup</span>
  <h3 class="title">Database</h3>
  <p class="subtitle">Connect a database to the component. To set up a new database go to the dataflow tab</p>
  <div style="display: flex">
    <div class="col-md-3 dataflow-name">
      <span class="title">Name</span>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let column of dataf_name; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-description>
              <div>
                {{ column.name }}
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-radio-button *ngFor="let out of column.output" (change)="getTableData(out.id, out.name, column.id)">{{
            out.name
          }}</mat-radio-button>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="col-md-8">
      <ag-grid-angular
        #agGrid
        style="width: 55vw; height: 70vh"
        class="ag-theme-alpine-dark"
        [rowData]="modelDataSource"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
      >
      </ag-grid-angular>
    </div>
  </div>
</div>

<div class="buildbutton">
  <button class="flatbuttongrey" mat-flat-button color="primary" (click)="cancel()">Cancel</button>
  <button class="flatbutton" mat-flat-button color="primary" (click)="selectedValue(modelDataSource)">Select</button>
</div>
