<!-- <mat-dialog-content class="mat-typography"> -->
    <div class="center-content">
        <mat-icon style="float: right;" mat-dialog-close>close</mat-icon>
        <div class="header">Named Entity Recognition</div>
        <div class="main-header">Entity Select</div>
        <div class="description">Find and select one or more of our pre defined entities.</div>
        <div class="main-content">
            <div class="selecttopic">
                <mat-form-field class="searchField">
                    <input matInput type="text" [(ngModel)]="entitySearchStr" (keyup)="filterTopics()" placeholder="Search">
                    <button (click)="filterTopics()" mat-button matPrefix mat-icon-button>
                      <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>            
                <div class="topiclist">
                    <div class="topic">
                        <div class="ct-head-rect">
                            <div class="ct-head">
                                Trained Entity Types
                            </div>
                        </div>
                        <!-- <div class="ct-data-rect">
                            <div class="ct-head">
                                <mat-checkbox class="example-margin" #select_all (change)="selectAll(select_all)">Select All</mat-checkbox>
                            </div>
                        </div> -->
                        <!-- <div class="ct-data-rect" *ngFor="let topic of filteredlistOfTopics">
                            <div class="ct-head">
                                <mat-checkbox class="example-margin" [(ngModel)]="topic.selected" (ngModelChange)="UpdateTopicLists(topic)">{{topic.ruleset_name}}</mat-checkbox>
                            </div>
                        </div> -->
                        <mat-radio-group  [(ngModel)]="ruleset_selected" (ngModelChange)="UpdateTopicLists(ruleset_selected)">
                            <mat-radio-button class="rule-list" *ngFor="let topic of filteredlistOfTopics" [value]="topic">
                                {{topic.ruleset_name}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </div>
    
            <div class="base-rect">
                <div class="rect-header">Definition</div>
                <div class="rectangle-1">
                    <div class="head">Name</div>
                    <div class="value">{{topicDetail.ruleset_name}}</div>
                </div>
                <div class="rectangle-1">
                    <div class="head">Description</div>
                    <div class="value">{{topicDetail.ruleset_description}}</div>
                </div>
                <div class="rectangle-examples">
                    <div class="head">Rules</div>
                    <table class="nertable">
                        <thead>
                            <th>Input Text</th>
                            <th>Replace With </th>
                          
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of custom_rules ; let index=index">
                                <td><span class="tag"
                                        [ngStyle]="{'background-color': item.color}">{{item.input_text}}</span>
                                </td>
                                <td>{{item.replace_with}}</td>
                                <!-- <td style="font-size: 11px;">
                                    <span>{{item.tot_variants ? item.tot_variants : "0"}}
                                        variants</span>
                                    <div>
                                        <div *ngFor="let variants of item.variants">{{variants}}
                                        </div>
                                    </div>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
        </div>
    
        <div class="buildbutton">
            <button class="flatbuttongrey" mat-flat-button color="primary" mat-dialog-close (click)="cancel()">Cancel</button>
            <button class="flatbutton" mat-flat-button color="primary" mat-dialog-close (click)="UpdateTopicList(topicDetail)">Add</button>
        </div>
    </div>
    <!-- </mat-dialog-content> -->
    <!-- <mat-dialog-actions align="end"> -->
    <!-- </mat-dialog-actions> -->
    