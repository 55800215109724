import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationService } from './services/authentication.service';
import { ErrorInterceptor } from './services/HTTPInterceptor';
import { JwtInterceptor } from './services/JWTInterceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MaterialModule } from './modules/global/material/material.module';
import { LoaderService } from './modules/global/loader/loader.service';
import { LoaderComponent } from './modules/global/loader/loader.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonConfirmationDialogComponent } from './modules/global/common-confirmation-dialog/common-confirmation-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { PrimengModule } from './modules/global/primeng/primeng/primeng.module';
import { CommonErrorDialogComponent } from './modules/global/common-error-dialog/common-error-dialog.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SidenavComponent } from './modules/sidenav/sidenav.component';
import { RightSidenavComponent } from './modules/right-sidenav/right-sidenav.component';
import { SidenavService } from './modules/sidenav/sidenav.service';
import { DateAgoPipe } from './modules/home/common/docview/dateago.pipe';
import { DatePipe } from '@angular/common';
import { RulesetFinderComponent } from './modules/home/utilities/text-pre-process/ruleset-finder.component';

import { PortalModule } from '@angular/cdk/portal';
import { SideTableComponent } from './modules/home/application/app-components/table/side-table.component';
import { TableComponent } from './modules/home/application/app-components/table/table.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    CommonConfirmationDialogComponent,
    CommonErrorDialogComponent,
    SidenavComponent,
    SideTableComponent,
    TableComponent,
    RightSidenavComponent,
    DateAgoPipe,
    RulesetFinderComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MaterialModule,
    PrimengModule,
    LayoutModule,
    PortalModule,
    // PdfViewerModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [PrimengModule],
  providers: [
    AuthenticationService,
    SidenavService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // {provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig},
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    LoaderService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
