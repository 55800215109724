import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { NumberSequence } from 'ag-grid-community';
import { pageService } from './pageService.service';
import { SelectTableComponent } from './select-table.component';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @ViewChild('sideTable', { static: true }) sidenav: MatSidenav;

  constructor(public dialog: MatDialog, private pageService: pageService) {}

  ngOnInit(): void {
    // this.pageService.setSidenav(this.sidenav);
  }
  selectedIndex: number = 0;
  // numbers = [0, 1, 2, 3, 4, 5];

  // nextStep() {
  //   if (this.selectedIndex != this.numbers.length) {
  //     this.selectedIndex = this.selectedIndex + 1;
  //   }
  //   console.log(this.selectedIndex);
  // }

  previousStep() {
    if (this.selectedIndex != 0) {
      this.selectedIndex = this.selectedIndex - 1;
    }
    console.log(this.selectedIndex);
  }

  openSearchDialog() {
    const dialogRef = this.dialog.open(SelectTableComponent);

    dialogRef.afterClosed().subscribe((result) => {
      let newRule = JSON.parse(localStorage.getItem('modelTemplate'));
      if (result != null) {
        this.selectedIndex = 2;
      }
    });
  }

  close() {
    this.sidenav.close();
  }
}
