import { Component, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './modules/sidenav/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'nocode';

  mode = new FormControl('over');
  toggleActive: boolean = false;

  constructor(private sidenavService: SidenavService) {}

  toggleRightSidenav() {
    this.toggleActive = !this.toggleActive;
    this.sidenavService.toggle();
  }
}
