import { ComponentRef, ComponentFactoryResolver, Injectable, Inject, ReflectiveInjector } from '@angular/core';
import { jsPlumb } from 'jsplumb';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CustomAlertService } from 'src/app/services/custom-alert.service';
import { publish_template, trigger_new_model } from 'src/app/services/global-data-urls';
import { ModelNodeComponent, Node } from './model-node/model-node.component';

@Injectable({
  providedIn: 'root',
})
export class ModelNodeService {
  private rootViewContainer: any;
  topicList = [];
  jsPlumbInstance: any;
  isDetailedViewChange: Subject<boolean> = new Subject<boolean>();
  refreshJsonStructure: Subject<boolean> = new Subject<boolean>();
  template: Subject<boolean> = new Subject<boolean>();
  curr_click: BehaviorSubject<any> = new BehaviorSubject({});
  reddit_curr_click: BehaviorSubject<any> = new BehaviorSubject({});
  isDetailedView: boolean = true;
  modelnodeComponent: ComponentRef<ModelNodeComponent>;

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private customAlertService: CustomAlertService,
    private apiservice: ApiService,
  ) {
    this.isDetailedViewChange.subscribe((value) => {
      this.isDetailedView = value;
    });
  }

  init() {
    this.jsPlumbInstance = jsPlumb.getInstance();
  }

  toggleDetailedView() {
    this.isDetailedViewChange.next(!this.isDetailedView);
  }

  public setRootViewContainerRef(viewContainerRef) {
    this.rootViewContainer = viewContainerRef;
  }

  public addDynamicNode(node: Node) {
    const factory = this.factoryResolver.resolveComponentFactory(ModelNodeComponent);
    const component = factory.create(this.rootViewContainer.parentInjector);
    (<any>component.instance).node = node;
    (<any>component.instance).jsPlumbInstance = this.jsPlumbInstance;
    this.rootViewContainer.insert(component.hostView);
  }

  addConnection(connection) {
    this.jsPlumbInstance.connect({ uuids: connection.uuids });
  }

  public clear() {
    this.rootViewContainer.clear();
  }

  json_struct: any;
  deleteAllNodes() {
    // this.jsPlumbInstance.deleteEveryEndpoint();
    this.initcount = 0;
    let a = this.getJsonStructure();
    if (a.nodes.length > 0) {
      this.json_struct = a;

      a.nodes.forEach((node) => {
        this.jsPlumbInstance.remove(node.id);
      });
    }
  }

  getJsonStructure() {
    let isAppbuilder = JSON.parse(localStorage.getItem('isAppbuilder'));
    if (isAppbuilder) {
      return JSON.parse(decodeURI(localStorage.getItem('json_struct')));
    } else {
      return JSON.parse(decodeURI(localStorage.getItem('model_json_struct')));
    }
  }

  publish_template() {
    let tempapp = JSON.parse(localStorage.getItem('modelTemplate'));
    let template_index = tempapp.templates.findIndex((a) => a.active);
    let obj = {
      app_name: tempapp.templates[template_index].app_name,
      user_email: localStorage.getItem('username'),
      env_name: tempapp.env_name,
    };
    let getUrl = `${publish_template}`;
    this.apiservice.postData(getUrl, obj).subscribe(
      (response) => {
        // [UN] wrote the if condition to handle the template not published case and
        // prevent the screen from being disabled on publish failure
        if (response.success) {
          tempapp.templates[template_index].published = true;
          localStorage.setItem('modelTemplate', JSON.stringify(tempapp));
          this.customAlertService.open(response.message, '');
          if (localStorage.getItem('isUseModel') != 'true' || localStorage.getItem('isUseSAModel') != 'true') {
            this.trigger_new_model(obj);
          }
        } else {
          this.customAlertService.open(response.message, '');
        }
      },
      (error) => {},
    );
  }

  trigger_new_model(payload) {
    let tempapp = JSON.parse(localStorage.getItem('modelTemplate'));
    let template_index = tempapp.templates.findIndex((a) => a.active);
    payload.knf_name = tempapp.templates[template_index].data.find((a) => a.comp_type == 'kf').knowledge_function_name; //localStorage.getItem('knf_name');
    this.apiservice.postData(`${trigger_new_model}`, payload).subscribe((response) => {
      // console.log(response);
    });
  }

  count = 0;
  get initcount() {
    return this.count;
  }
  set initcount(data) {
    this.count = data;
  }
}
