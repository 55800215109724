import { baseUrl } from 'src/environments/environment';

//const basicURL = `https://${window.location.href.includes("minerva") ? 'minerva' : 'nocode'}.accrete.ai`;
const basicURL = baseUrl;
export const signupUrl = `${basicURL}/userapi/auth/register`;
export const signinUrl = `${basicURL}/userapi/auth/jwt/login`; // ?filters=sponsor_scid:eq:Stratus-Technologies-2.pdf
export const resetPwdUrl = `${basicURL}/userapi/auth/reset-password`;
export const forgetPwdUrl = `${basicURL}/userapi/auth/forgot-password`;
export const createBuildUrl = `${basicURL}/userapi/app/create_build_settings_v2`;
export const rawDataUrl = `${basicURL}/userapi/reddit/get_reddit_raw_data_v2`;
export const metaDataUrl = `${basicURL}/userapi/reddit/get_reddit_posts_meta_data_v2`;
export const chartDataUrl = `${basicURL}/userapi/reddit/get_reddit_chart_data`;
export const listOfAppUrl = `${basicURL}/userapi/app/get_apps_list`;
export const listOfOutputColumns = `${basicURL}/userapi/app/get_datahook_output_columns?root_context=`;
export const getAppDetailsUrl = `${basicURL}/userapi/app/get_all_app_templates`;
export const uploadcsvdatahook = `${basicURL}/userapi/csv/upload_csv_file`;
export const suggestaliasonterms = `${basicURL}/userapi/topic_classification/get_topics_suggestions_on_keywords`;
export const suggestaliasonsnippets = `${basicURL}/userapi/topic_classification/get_topic_suggestions_for_snippets`;
export const topicCategoryList = `${basicURL}/userapi/topic_classification/get_topic_finder_data`;
export const topicSubmit = `${basicURL}/userapi/topic_classification/create_knowledge_function`;
export const newTopic = `${basicURL}/userapi/topic_classification/create_new_topic`;
export const outputMetaColumns = `${basicURL}/userapi/app/get_meta_data_output_columns`;
export const outputMetaFunctions = `${basicURL}/userapi/app/get_meta_data_knowledge_functions`;
export const outputMetaEntity = `${basicURL}/userapi/topic_classification/get_entities_list_of_data_hook`;
export const saveMetaTableConfig = `${basicURL}/userapi/app/save_meta_table_configuration`;
// export const getTrainingData = `${basicURL}/userapi/topic_classification/get_data_for_feedback`;
export const getTrainingData = `${basicURL}/userapi/topic_classification/get_training_feedback_data`;
export const postTrainingDataFeedback = `${basicURL}/userapi/topic_classification/update_data_for_feedback`;
export const listOfPrebuildDataHook = `${basicURL}/userapi/app/${localStorage.getItem(
  'username',
)}/get_consolidated_apps_list`;
export const listOfKfUrl = `${basicURL}/userapi/app/${localStorage.getItem(
  'username',
)}/get_all_template_knowledge_functions`;
export const checkForAppAvailability = `${basicURL}/userapi/app/check_if_app_exists`;
export const getCsv = `${basicURL}/userapi/csv/get_csv_data`;
export const uploadCsv = `${basicURL}/userapi/csv/files`;
export const get_category_details = `${basicURL}/userapi/sentiment_analysis/get_category_details`;
export const create_new_category = `${basicURL}/userapi/sentiment_analysis/create_new_category`;
export const get_snippet_topic = `${basicURL}/userapi/sentiment_analysis/get_topic_snippets`;
export const get_trained_untrained_topic = `${basicURL}/userapi/sentiment_analysis/get_trained_untrained_topics`;
export const twitter_categories = `${basicURL}/userapi/twitter/twitter_categories`;
export const delete_app = `${basicURL}/userapi/app/delete_apps_list`;
export const get_training_stats = `${basicURL}/userapi/topic_classification/get_training_stats`;
export const save_app_state = `${basicURL}/userapi/app/save_app_state`;
export const get_app_state = `${basicURL}/userapi/app/get_app_state`;
export const scrap_hist_reddit_post = `${basicURL}/userapi/app/scrap_hist_reddit_post`;
export const createPdfApp = `${basicURL}/userapi/pdf2text/create_pdf2text_app_datahook`;
export const getDownloadUrls = `${basicURL}/userapi/pdf2text/get_download_urls`;
export const extractdata = `${basicURL}/userapi/pdf2text/extract_text`;
export const deletePDFFile = `${basicURL}/userapi/pdf2text/delete_pdf_file`;
export const get_datahook_templates = `${basicURL}/userapi/app/get_datahook_templates`;
export const getPDFUploadUrls = `${basicURL}/userapi/pdf2text/get_upload_urls`;
export const getEntityList = `${basicURL}/userapi/topic_classification/get_entities_list`;
export const nerSubmit = `${basicURL}/userapi/topic_classification/create_ner_knowledge_function`;
export const update_normalization = `${basicURL}/userapi/topic_classification/update_normalization`;
export const get_snippets_from_datahook = `${basicURL}/userapi/reddit/get_snippets_from_datahook`;
export const topic_discovery = `${basicURL}/userapi/reddit/topic_discovery`;
export const get_ungrouped_topic_finder_data = `${basicURL}/userapi/topic_classification/get_ungrouped_topic_finder_data`;
// change to basicURL later
export const delete_feedback_data = `${basicURL}/userapi/topic_classification/delete_feedback_data`;
export const get_normalization_data = `${basicURL}/normApi/getData/get_normalised_data`;
export const save_normalization_data = `${basicURL}/normApi/getData/save_normalised_data`;
export const del_normalization_data = `${basicURL}/normApi/getData/del_normalised_data`;
export const get_user_selected_ner_entities = `${basicURL}/userapi/topic_classification/get_user_selected_ner_entities`;
export const retry_prov_model = `${basicURL}/userapi/topic_classification/retry_prov_model`;
export const get_model_datahooks = `${basicURL}/userapi/app/get_model_datahooks`;
export const get_model_datahooks_post = `${basicURL}/userapi/app/get_model_datahooks_post`;
export const get_models = `${basicURL}/userapi/app/get_models`;
export const get_models_kf = `${basicURL}/userapi/app/get_models_kf`;
export const get_models_kf_post = `${basicURL}/userapi/app/get_models_kf_post`;
export const create_model = `${basicURL}/userapi/app/create_model`;
export const create_new_environment = `${basicURL}/userapi/app/create_new_environment`;
export const get_environment_apps_models = `${basicURL}/userapi/app/get_environment_apps_models`;
export const number_of_tweets = `${basicURL}/userapi/twitter/number_of_tweets`;
export const remove_template_from_env = `${basicURL}/userapi/app/remove_template_from_env`;
export const add_template_into_env = `${basicURL}/userapi/app/add_template_into_env`;
// export const complete_validation = `${basicURL}/userapi/topic_classification/complete_validation` ;
export const get_models_in_env = `${basicURL}/userapi/app/get_models_in_env`;
export const publish_template = `${basicURL}/userapi/app/publish_template`;
export const change_datahook = `${basicURL}/userapi/app/change_datahook`;
export const get_naviga_dropdowns = `${basicURL}/userapi/naviga/get_naviga_dropdowns`;
export const get_users = `${basicURL}/userapi/entitlements/get_users`;
export const set_user_roles = `${basicURL}/userapi/entitlements/set_user_roles`;
export const delete_user = `${basicURL}/userapi/entitlements/delete_user`;
export const getroles = `${basicURL}/userapi/roles/`;
export const get_all_page_role = `${basicURL}/userapi/roles/get_all_page_roles`;
export const get_environments = `${basicURL}/userapi/app/get_environments`;
export const create_or_update_role = `${basicURL}/userapi/roles/create_or_update_role`;
export const get_role_info = `${basicURL}/userapi/roles/get_role_info`;
export const delete_role = `${basicURL}/userapi/roles/delete_role`;
export const user_feedback = `${basicURL}/userapi/topic_classification/user_feedback`;
export const validation_list = `${basicURL}/userapi/topic_classification/validation_list`;
export const complete_validation = `${basicURL}/userapi/topic_classification/complete_validation`;
export const trigger_ml_ner_pipeline = `${basicURL}/userapi/topic_classification/trigger_ml_ner_pipeline`;
export const trigger_improve_model = `${basicURL}/userapi/topic_classification/trigger_improve_model`;
export const update_endpoint_weightage = `${basicURL}/userapi/topic_classification/update_endpoint_weightage`;
export const model_list = `${basicURL}/userapi/topic_classification/model_list`;
export const active_instance = `${basicURL}/userapi/topic_classification/active_instance`;
export const get_features = `${basicURL}/userapi/features/get_features`;
export const aggregate_validations = `${basicURL}/userapi/topic_classification/aggregate_validations`;
export const delete_validation_set = `${basicURL}/userapi/topic_classification/delete_validation_set`;
export const tc_get_comments = `${basicURL}/userapi/topic_classification/get_comments`;
export const tc_insert_comments = `${basicURL}/userapi/topic_classification/insert_comments`;
export const delete_env = `${basicURL}/userapi/app/delete_env`;
export const check_duplicate = `${basicURL}/userapi/app/check_duplicate`;
export const invite_user = `${basicURL}/userapi/entitlements/invite_user`;
export const publish_api = `${basicURL}/userapi/topic_classification/publish_api`;
export const okta_login = `${basicURL}/userapi/okta/okta-login`;
export const get_env_details = `${basicURL}/userapi/app/get_env_details`;
export const okta_logout = `${basicURL}/userapi/okta/logout`;
export const list_pdf_text = `${basicURL}/userapi/app/list_pdf_text`;
export const fetch_tsr_summary = `${basicURL}/userapi/table_extraction/fetch_tsr_summary`;
export const extract_page_table_data = `${basicURL}/userapi/table_extraction/extract_page_table_data`;
export const okta_authorization_callback = `${basicURL}/userapi/okta/authorization-callback`;
export const update_env = `${basicURL}/userapi/app/update_env`;
export const model_finder = `${basicURL}/userapi/topic_classification/model_finder`;
export const save_snippets = `${basicURL}/userapi/topic_classification/s3_save_snippets`;
export const trigger_new_model = `${basicURL}/userapi/topic_classification/trigger_new_model`;
export const get_prov_models = `${basicURL}/userapi/topic_classification/get_prov_models`;
export const fetch_notification = `${basicURL}/userapi/notification/fetch_notification`;
export const read_notification = `${basicURL}/userapi/notification/read_notification`;
export const clear_notification = `${basicURL}/userapi/notification/clear_notification`;
export const update_tsr_structure = `${basicURL}/userapi/table_extraction/update_tsr_structure`;
export const train_tsr_feedback = `${basicURL}/userapi/table_extraction/train_tsr_feedback`;
export const s3_read_snippets = `${basicURL}/userapi/topic_classification/s3_read_snippets`;
export const list_model_category = `${basicURL}/userapi/model_provisioning/list_model_category`;
export const get_environment_app = `${basicURL}/userapi/app/get_environment_app`;
export const get_ruleset_list = `${basicURL}/userapi/app/get_ruleset`;
export const create_ruleset = `${basicURL}/userapi/app/create_ruleset`;
export const g_drive_authorize = `${basicURL}/userapi/google/authorize`;
export const get_google_files = `${basicURL}/userapi/google/files`;
export const get_data = `${basicURL}/userapi/google/get_data`;
export const revoke = `${basicURL}/userapi/google/revoke`;
export const use_components = `${basicURL}/userapi/app/use_components`;
export const list_table_type = `${basicURL}/userapi/table_extraction/list_table_type`;
export const save_data = `${basicURL}/userapi/google/save_data`;
export const save_draft = `${basicURL}/userapi/app/dh_kf`;
export const save_tsr_csv = `${basicURL}/userapi/table_extraction/save_tsr_csv`;
export const aws_get_files = `${basicURL}/userapi/aws_io/files`;
export const move_file = `${basicURL}/userapi/aws_io/move_file`;
export const aws_io_save_data = `${basicURL}/userapi/aws_io/save_data`;
export const explainability = `${basicURL}/userapi/topic_classification/explainability`;
export const topic_matrix = `${basicURL}/userapi/topic_classification/topic_matrix`;
export const template_metrics = `${basicURL}/userapi/topic_classification/template_metrics`;
export const topic_confidence_score = `${basicURL}/userapi/topic_classification/topic_confidence_score`;
export const save_df = `${basicURL}/userapi/dataflow/save_df`;
export const list_df = `${basicURL}/userapi/dataflow/list_df`;
export const get_df = `${basicURL}/userapi/dataflow/get_df`;
export const get_db_data = `${basicURL}/userapi/dataflow/get_db_data`;
export const get_df_sample_preview = `${basicURL}/userapi/dataflow/get_df_sample_preview`;
export const list_ds = `${basicURL}/userapi/dataflow/list_ds`;
export const update_df_state = `${basicURL}/userapi/dataflow/update_df_state`;
export const activate_df = `${basicURL}/userapi/dataflow/activate_df`;
export const app_list = `${basicURL}/userapi/app_builder/list_application`;
export const create_application = `${basicURL}/userapi/app_builder/create_application`;
export const check_duplicate_app = `${basicURL}/userapi/app_builder/check_duplicate`;
export const delete_application = `${basicURL}/userapi/app_builder/delete_application`;
export const disable_application = `${basicURL}/userapi/app_builder/disable_application`;
export const clone_application = `${basicURL}/userapi/app_builder/clone_application`;
export const get_application = `${basicURL}/userapi/app_builder/get_application`;
export const save_df_app_state = `${basicURL}/userapi/dataflow/save_df_app_state`;
export const model_information = `${basicURL}/userapi/model_provisioning/model_information`;
export const previously_used_data = `${basicURL}/userapi/model_provisioning/previously_used_data`;
export const create_page = `${basicURL}/userapi/app_builder/create_page`;
export const page_output = `${basicURL}/userapi/app_builder/page_output`;
export const save_application = `${basicURL}/userapi/app_builder/save_application`;
export const publish_application = `${basicURL}/userapi/app_builder/publish_application`;
export const get_df_app_state = `${basicURL}/userapi/dataflow/get_df_app_state`;
export const recent_components = `${basicURL}/userapi/app_builder/recent_components`;
export const delete_page = `${basicURL}/userapi/app_builder/delete_page`;
