import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url
    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.access_token;
    //!request.url.includes("amazonaws") &&
    if (isLoggedIn && request.url.indexOf('/csv/files') == -1 && request.url.indexOf('amazonaws.com') == -1) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
          Id_token: currentUser.id_token,
          'Content-Type': 'application/json',
          //'x-api-key': '5MFTS4KBOL4zE2m3Q4IjS6r275NvzvVr8gBsBExr'
        },
      });
    }
    // if(isLoggedIn && request.url.indexOf('/google/files')){
    //     request = request.clone({
    //         setHeaders: {
    //             Authorization: `Bearer ${currentUser.access_token}`,
    //             "Id_token": currentUser.id_token,
    //             'Content-Type': 'application/json',
    //             'gauth': localStorage.getItem("gauth")
    //         }
    //     });
    // }

    return next.handle(request);
  }
}
