import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { save_app_state } from 'src/app/services/global-data-urls';
import { DataHookConnectionDialogComponent } from '../../common/data-hook-connection-dialog/data-hook-connection-dialog.component';
import { ModelNodeService } from '../model-node.service';

@Component({
  selector: 'app-model-node',
  templateUrl: './model-node.component.html',
  styleUrls: ['./model-node.component.scss'],
})
export class ModelNodeComponent implements AfterViewInit, OnInit {
  @Input() node: Node;
  @Input() jsPlumbInstance;
  isDetailedView: boolean = true;
  count = 0;
  constructor(
    private nodeService: ModelNodeService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private apiservice: ApiService,
    public dialog: MatDialog,
  ) {
    this.nodeService.isDetailedViewChange.subscribe((value) => {
      this.isDetailedView = value;
      //this.jsPlumbInstance.reset();
      // this.ngAfterViewInit();
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    const exampleDropOptions = {
      tolerance: 'touch',
      hoverClass: 'dropHover',
      activeClass: 'dragActive',
    };
    let input = {
      endpoint: ['Dot', { radius: 5 }],
      paintStyle: { fill: 'orange' },
      isSource: false,
      scope: 'jsPlumb_DefaultScope',
      connectorStyle: { stroke: '#99cb3a', strokeWidth: 1 },
      connector: ['Bezier', { curviness: 100 }],
      maxConnections: 30,
      isTarget: true,
      dropOptions: exampleDropOptions,
      connectorOverlays: [['Arrow', { location: 1 }]],
      connectionsDetachable: true,
    };
    let output = {
      endpoint: ['Dot', { radius: 5 }],
      paintStyle: { fill: '#17f01d' },
      isSource: true,
      scope: 'jsPlumb_DefaultScope',
      connectorStyle: { stroke: '#99cb3a', strokeWidth: 1 },
      connector: ['Bezier', { curviness: 100 }],
      maxConnections: 30,
      isTarget: false,
      connectorOverlays: [['Arrow', { location: 1 }]],
      dropOptions: exampleDropOptions,
      connectionsDetachable: true,
    };

    const { id } = this.node;
    if (
      this.node.type == 'reddit' ||
      this.node.type == 'twitter' ||
      this.node.type == 'custom_CSV' ||
      this.node.type == 'doc_uploader' ||
      this.node.type == 'naviga'
    ) {
      this.jsPlumbInstance.addEndpoint(id, { anchor: 'Right', uuid: id }, output);
    } else if (
      this.node.type == 'topic_classification' ||
      this.node.type == 'sentiment_analysis' ||
      this.node.type == 'ner' ||
      this.node.type == 'table_extraction' ||
      this.node.type == 'text_preprocess'
    ) {
      this.jsPlumbInstance.addEndpoint(id, { anchor: 'Right', uuid: id }, output);
      this.jsPlumbInstance.addEndpoint(id, { anchor: 'Left', uuid: id }, input);
    } else if (this.node.type == 'meta_data' || this.node.type == 'raw_data') {
      this.jsPlumbInstance.addEndpoint(id, { anchor: 'Left', uuid: id }, input);
    }

    let that = this;
    this.jsPlumbInstance.draggable(id, {
      drag: function (e) {
        // Your code comes here
        // this.jsPlumbInstance.repaint($(this));
      },
      stop: function (e) {
        setTimeout(function () {
          that.saveNodeJson();
        }, 1000);
      },
    });

    // let count = 0;
    // console.log(this.nodeService.initcount);
    if (this.nodeService.initcount != 1) {
      this.nodeService.initcount = 0;
    }
    this.count = this.nodeService.initcount;
    if (this.count == 0) {
      this.nodeService.initcount = 1;
      // const dialogRef = that.dialog.open(DataHookConnectionDialogComponent, {
      //   hasBackdrop: true,
      //   data: {
      //     "Source:": "params.sourceId",
      //     "Target": "params.targetId"
      //   },
      //   height: '70%',
      //   width: '60%',
      // });
      this.jsPlumbInstance.bind('beforeDrop', function (params) {
        const dialogRef = that.dialog.open(DataHookConnectionDialogComponent, {
          hasBackdrop: true,
          data: {
            Target: params.targetId,
            Source: params.sourceId,
          },
          height: '70%',
          width: '60%',
        });
        dialogRef.afterClosed().subscribe((result) => {});
        return true;
      });
    }
    // this.jsPlumbInstance.bind('connection',function(info,ev){
    //   var con=info.connection;   //this is the new connection
    //   console.log(info,ev);
    // });
    // allowLoopback:false
  }

  saveNodeJson() {
    let app = this.getTemplate(); //JSON.parse(localStorage.getItem('appTemplate'));
    let template_index = app.templates.findIndex((a) => a.active);
    let json_struct = JSON.parse(decodeURI(localStorage.getItem('model_json_struct')));
    let payload = {
      user_email: localStorage.getItem('username'),
      app_name: app.templates[template_index].app_name,
      app_state_json: [json_struct],
    };
    if (payload.app_name) {
      this.apiservice.postData(save_app_state, payload).subscribe(
        (response: any) => {},
        (err: any) => {},
      );
    }
  }

  getTemplate() {
    if (JSON.parse(localStorage.getItem('isAppbuilder'))) {
      return JSON.parse(localStorage.getItem('appTemplate'));
    } else {
      return JSON.parse(localStorage.getItem('modelTemplate'));
    }
  }

  nodeclick(id, type) {
    this.nodeService.curr_click.next({
      id: id,
      type: type,
    });
    localStorage.removeItem('temp_node');
    localStorage.setItem(
      'temp_node',
      JSON.stringify([
        {
          id: id,
          type: type,
        },
      ]),
    );
  }

  deleteNode(node) {
    let model_json_struct = decodeURI(localStorage.getItem('model_json_struct'));
    model_json_struct = JSON.parse(model_json_struct);
    let index = model_json_struct['nodes'].findIndex((a) => a.id == node.id);
    model_json_struct['nodes'].splice(index, 1);

    let tempapp = this.getTemplate();
    let template_index = tempapp.templates.findIndex((a) => a.active);
    let dhindex = tempapp.templates[template_index].data.findIndex(
      (a) =>
        a.comp_type == 'datahook' &&
        (a.datahook_name == node.datahook_name || a.datahook_name == undefined || a.datahook_name == ''),
    );
    if (dhindex >= 0) {
      tempapp.templates[template_index].data.splice(dhindex, 1);
    }
    let kfindex = tempapp.templates[template_index].data.findIndex(
      (a) =>
        a.comp_type == 'kf' &&
        (a.knowledge_function_name == node.knowledge_function_name ||
          a.knowledge_function_name == undefined ||
          a.knowledge_function_name == ''),
    );
    if (kfindex >= 0) {
      tempapp.templates[template_index].data.splice(kfindex, 1);
    }
    let utilityindex = tempapp.templates[template_index].data.findIndex(
      (a) =>
        a.comp_type == 'utilities' &&
        (a.ruleset_name == node.ruleset_name || a.ruleset_name == undefined || a.ruleset_name == ''),
    );
    if (utilityindex >= 0) {
      tempapp.templates[template_index].data.splice(utilityindex, 1);
    }

    localStorage.setItem('modelTemplate', JSON.stringify(tempapp));
    this.jsPlumbInstance.remove(node.id);

    if (
      node.type == 'reddit' ||
      node.type == 'twitter' ||
      node.type == 'custom_CSV' ||
      node.type == 'doc_uploader' ||
      node.type == 'naviga'
    ) {
      let isJson = model_json_struct['nodes'].find(
        (a) =>
          a.type == 'reddit' ||
          a.type == 'twitter' ||
          a.type == 'custom_CSV' ||
          a.type == 'doc_uploader' ||
          a.type == 'naviga',
      );
      if (!isJson) {
        this.jsPlumbInstance.remove('raw_data');
      }
    }

    localStorage.setItem('model_json_struct', JSON.stringify(model_json_struct));
    this.router.navigateByUrl('/app-builder');
  }

  myFunction(event) {
    // console.log(event);
  }
}
export interface Node {
  id: string;
  top?: number;
  left?: number;
  type: string;
}
