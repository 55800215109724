<div class="modelnode reddit" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'reddit')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='reddit'">
    <div class="row header">
        <div class="col-md-10">Reddit</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.datahook_name ? node.datahook_name : 'Subreddits'}}</div>
        <div class="col-md-12 text-left">Seed Terms</div>
        <div class="col-md-12 text-left">Users</div>
    </div>
</div>

<div class="modelnode twitter" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'twitter')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='twitter'">
    <div class="row header">
        <div class="col-md-10">Twitter</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.datahook_name ? node.datahook_name : 'Categories'}}</div>
        <div class="col-md-12 text-left">Seed Terms</div>
    </div>
</div>

<div class="modelnode twitter" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'doc_uploader')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='doc_uploader'">
    <div class="row header">
        <div class="col-md-10">Doc Uploader</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.datahook_name ? node.datahook_name : ''}}</div>
        <div class="col-md-12 text-left">Upload</div>
    </div>
</div>

<div class="modelnode twitter" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'custom_CSV')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='custom_CSV'">
    <div class="row header">
        <div class="col-md-10">Custom CSV</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.datahook_name ? node.datahook_name : ''}}</div>
        <div class="col-md-12 text-left">Upload</div>
    </div>
</div>

<div class="modelnode twitter" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'naviga')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='naviga'">
    <div class="row header">
        <div class="col-md-10">Naviga</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.datahook_name ? node.datahook_name : ''}}</div>
        <div class="col-md-12 text-left">Output</div>
    </div>
</div>

<div *ngIf="node.type=='topic_classification'" (dblclick)="nodeclick(node.id, 'topic_classification')">
    <div class="modelnode tc" id="{{node.id}}" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
        style="height: 96px;">
        <div class="row header">
            <div class="col-md-10 setpad">Topic Classification</div>
            <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
        </div>
        <div class="row">
            <div class="col-md-12 text-left">{{node.knowledge_function_name ? node.knowledge_function_name : 'Topics'}}</div>
            <div class="col-md-12 text-left">Seed Terms</div>
            <div class="col-md-12 text-left">Snippets</div>
        </div>
    </div>
</div>

<div *ngIf="node.type=='ner'" (dblclick)="nodeclick(node.id, 'ner')">
    <div class="modelnode tc" id="{{node.id}}" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
        style="height: 96px;">
        <div class="row header">
            <div class="col-md-10 setpad">NER</div>
            <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
        </div>
        <div class="row">
            <div class="col-md-12 text-left">{{node.knowledge_function_name ? node.knowledge_function_name : ''}}</div>
            <div class="col-md-12 text-left">data in</div>
            <div class="col-md-12 text-left">reference dictionary</div>
        </div>
    </div>
</div>

<div class="modelnode sa" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'sentiment_analysis')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='sentiment_analysis'">
    <div class="row header">
        <div class="col-md-10 setpad">Sentiment Analysis</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.knowledge_function_name ? node.knowledge_function_name : ''}}</div>
        <div class="col-md-12 text-left">Snippets</div>
        <div class="col-md-12 text-left">Sentiments</div>
    </div>
</div>

<div class="modelnode sa" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'table_extraction')" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='table_extraction'">
    <div class="row header">
        <div class="col-md-10 setpad">Table Extraction</div>
        <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">{{node.knowledge_function_name ? node.knowledge_function_name : ''}}</div>
        <div class="col-md-12 text-left">Upload</div>
    </div>
</div>

<div class="modelnode rd" id="{{node.id}}" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='raw_data'">
    <div class="row header">
        <div class="col-md-12">Raw Data Table</div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">Data</div>
    </div>
</div>

<div class="modelnode md" id="{{node.id}}" (dblclick)="nodeclick(node.id, 'meta_data')"  [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
    style="height: 96px;" *ngIf="node.type=='meta_data'">
    <div class="row header">
        <div class="col-md-12">Meta Data Table</div>
    </div>
    <div class="row">
        <div class="col-md-12 text-left">Data</div>
    </div>
</div>

<div *ngIf="node.type=='text_preprocess'" (dblclick)="nodeclick(node.id, 'text_preprocess')">
    <div class="modelnode tpp" id="{{node.id}}" [style.top.px]="node.top || 62" [style.left.px]="node.left || 70"
        style="height: 96px;">
        <div class="row header">
            <div class="col-md-10 setpad">Text Pre processing</div>
            <div class="col-md-2 setpad" (click)="deleteNode(node)"><i class="fa fa-trash"></i></div>
        </div>
        <div class="row">
            <div class="col-md-12 text-left">{{node.ruleset_name ? node.ruleset_name : ''}}</div>
        </div>
    </div>
</div>