// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};
export const baseUrl = 'https://nocode.accrete.ai';
export const webSocket_base_url = 'wss://nocode.accrete.ai/ws/';

// export const baseUrl = "https://nebula-webui-dev.accrete.ai"
// export const webSocket_base_url = "wss://nebula-webui-dev.accrete.ai:5678/ws/"

// export const baseUrl = "https://nebula.accrete.ai"
// export const webSocket_base_url = "wss://nebula.accrete.ai:5678/ws/"

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
