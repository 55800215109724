import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { webSocket_base_url } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  subject: WebSocketSubject<any>;

  constructor() {
    this.connect();
  }

  public connect() {
    this.subject = webSocket({
      url: webSocket_base_url + localStorage.getItem('username'), //webSocket_base_url + localStorage.getItem("username"),//"ws://172.31.20.145:5678/ws/sameer",
      openObserver: {
        next: () => {
          // console.log('connexion ok');
        },
      },
      closeObserver: {
        next: () => {
          // console.log('disconnect ok');
        },
      },
    });
  }

  public send(msg: string) {
    this.subject.next(msg);
  }

  public disconnect() {
    this.subject.complete();
  }
}
