import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './sidenav.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChild('rightSidenav', { static: true }) sidenav: MatSidenav;

  @Input()
  openNav: boolean;
  @Output() notification_read = new EventEmitter();

  constructor(private sidenavService: SidenavService) {}

  ngOnInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  close() {
    this.sidenav.close();
  }
  isread(event) {
    this.notification_read.emit(event);
  }
}
