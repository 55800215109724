import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class pageService {
  isDetailedViewChange = new Subject<any>();
  tableData = [];
  // nodeComponent: ComponentRef<NodeComponent>;

  constructor() {
    this.isDetailedViewChange.subscribe((value) => {
      this.tableData = value;
    });
  }
  sendDataToOtherComponent(datafromrows) {
    this.isDetailedViewChange.next(datafromrows);
  }
}
