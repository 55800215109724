import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { CustomAlertService } from 'src/app/services/custom-alert.service';
import { change_datahook, listOfOutputColumns } from 'src/app/services/global-data-urls';

@Component({
  selector: 'app-data-hook-connection-dialog',
  templateUrl: './data-hook-connection-dialog.component.html',
  styleUrls: ['./data-hook-connection-dialog.component.scss']
})
export class DataHookConnectionDialogComponent implements OnInit {

  listOfTopics: any[] = [];
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: any = [];
  gridApi;
  gridColumnApi;
  dhtype: any;
  app: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiService: ApiService,
    private customAlertService: CustomAlertService, public dialogRef: MatDialogRef<DataHookConnectionDialogComponent>) {
  }

  tempapp: any;
  isAppbuilder: boolean = true;
  template_index: any;
  base_component: any;

  ngOnInit(): void {
    this.isAppbuilder = JSON.parse(localStorage.getItem('isAppbuilder'));
    this.columnDefs = [
      {
        field: 'fields',
        headerName: 'Raw Data Fields',
        checkboxSelection: true
      },
      {
        field: 'type',
        headerName: 'Data Type',
      }
    ];
    this.defaultColDef = {
      width: 250,
      resizable: true,
      wrapText: true,
      autoHeight: true
    };
    this.rowSelection = 'multiple';

    this.tempapp = this.getTemplate();
    this.template_index = this.tempapp.templates.findIndex(a => a.active);
    let a = this.data["Source"].split('~');
    this.base_component = this.data["Target"].split('~')[0];
    this.setGrid(a[0]);
    let model_json_struct = JSON.parse(decodeURI(localStorage.getItem('model_json_struct')));
    let kf = model_json_struct.nodes.find(a1=> a1.id == this.data["Target"]);
    let editIndex = this.tempapp.templates[this.template_index].data.length - 1;
    // this.app = this.tempapp.templates[this.template_index].data[editIndex].find(ele => ele.knowledge_function_name == kf.knowledge_function_name);
    this.app = this.tempapp.templates[this.template_index].data[editIndex];
  }

  getTemplate() {
    if (this.isAppbuilder) {
      return JSON.parse(localStorage.getItem('appTemplate'));
    }
    else {
      return JSON.parse(localStorage.getItem('modelTemplate'));
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setGrid(this.app.root_context);
  }

  private setGrid(dhtype) {
    if (dhtype == "custom") {
      for (let col of this.app.datasource_input_columns) {
        this.rowData = [...this.rowData, { fields: col, type: 'text' }];
      }
    } else if (dhtype == "csv") {
      let columns = JSON.parse(localStorage.getItem('csvUpload')).headers;
      for (let col of columns) {
        this.rowData = [...this.rowData, { fields: col.field, type: col.datatype || 'text' }];
      }
    } else if (dhtype == "reddit") {
      this.apiService.getData(`${listOfOutputColumns}reddit`).subscribe((response: any) => {
        this.rowData = response.ouput_columns_mapping.map((column: any) => ({ fields: column.display_column, type: column.datatype || 'text' }));
      }, (error => {
        this.customAlertService.open("Failed to load", "");
      }));
    }
    else if (dhtype == "twitter") {
      this.apiService.getData(`${listOfOutputColumns}twitter`).subscribe((response: any) => {
        this.rowData = response.ouput_columns_mapping.map((column: any) => ({ fields: column.display_column, type: column.datatype || 'text' }));
      }, (error => {
        this.customAlertService.open("Failed to load", "");
      }));
    }
  }

  UpdateTopicList() {
    // let topicDetail = this.listOfTopics.filter((data:any)=>data.selected);
    // localStorage.setItem('cancelStatusUpdate','update');
    // localStorage.setItem('topicFinderTopic',JSON.stringify(topicDetail));
  }

  Done() {
    // let selectedcol = { datasource_input_columns: this.gridApi.getSelectedRows().map((data: any) => data.fields) };

    let model_json_struct = JSON.parse(decodeURI(localStorage.getItem('model_json_struct')));
    let dh = model_json_struct.nodes.find(a=> a.id == this.data["Source"]);
    let kf = model_json_struct.nodes.find(a=> a.id == this.data["Target"]);

    let payload = {
      "user_email": localStorage.getItem("username"),
      "env_name": this.tempapp.env_name,
      "app_name": this.tempapp.templates[this.template_index].app_name,
      "knowledge_function_name": kf.knowledge_function_name ? kf.knowledge_function_name :this.data["Target"],
      "datasource_name": [
        dh.datahook_name ? dh.datahook_name : this.data["Source"]
      ],
      "datasource_input_columns": this.gridApi.getSelectedRows().map((data: any) => data.fields)
    }

    this.apiService.postData(`${change_datahook}`, payload).subscribe((response: any) => {
      this.customAlertService.open(response.message);
      
    })
    this.dialogRef.close();
  }
}
