import { HttpBackend, HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  // Response from check_if_app_exists
  existingApp: any;

  constructor(private http: HttpClient, private handler: HttpBackend) {}

  getData(url, params = {}): Observable<any> {
    return this.http.get(url, { params });
  }

  postData(url, payload, params = {}): Observable<any> {
    return this.http.post(url, payload, { params });
  }

  deleteData(url): Observable<any> {
    return this.http.delete(url);
  }

  postDataUpload(url, file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(url, formData).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  postPDFUpload(url, file): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http.put<any>(url, formData).pipe(
      map((data) => {
        return data;
      }),
    );
  }

  uploadfileAWSS3(fileuploadurl: string, file: File): Observable<HttpEvent<{}>> {
    this.http = new HttpClient(this.handler); // to reset the header
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    const req = new HttpRequest('PUT', fileuploadurl, file, { headers: headers, reportProgress: true });
    return this.http.request(req);
  }

  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  google_getapi(url) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('gauth', localStorage.getItem('gauth'));
    return this.http.get(url, {
      headers: headers,
    });
  }

  google_postapi(url, payload) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      .set('gauth', localStorage.getItem('gauth'));
    return this.http.post(url, payload, {
      headers: headers,
    });
  }
}
