<div class="center-content">
    <div class="header">Connect Components</div>
    <div class="main-header">Map Incoming Fields</div>
    <div class="base-rect">
        <div class="row">
            <div class="col-md-6">
                <div class="rect-header">Connecting Components</div>
            </div>
            <div class="col-md-6">
                <div class="rect-header">Current Component</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="rectangle-1">
                    <ag-grid-angular style="height: 36vh" class="ag-theme-alpine-dark" [columnDefs]="columnDefs"
                        [defaultColDef]="defaultColDef" [rowSelection]="rowSelection" [groupSelectsChildren]="true"
                        [suppressRowClickSelection]="true" [suppressAggFuncInHeader]="true" [rowData]="rowData"
                        (gridReady)="onGridReady($event)"></ag-grid-angular>
                </div>
            </div>
            <div class="col-md-6">
                <div class="rectangle-1">
                    <div class="addcomphdr">
                        <div class="row">
                            <div class="col-md-10">
                                <div class="header" style="font-size: 11px;">Base Component</div>
                                <div class="value-1">{{base_component}}</div>
                            </div>
                            <div class="col-md-2">
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <div class="header" style="font-size: 11px;">Model Name</div>
                                <div class="value-1">{{app.knowledge_function_name ? app.knowledge_function_name : "-"}}</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-3">
                                <div class="header" style="font-size: 11px;">Model Creator</div>
                                <div class="value-1">Accrete</div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 mt-3">
                                <div class="header" style="font-size: 11px;">Description</div>
                                <div class="value-1">{{app.description ? app.description : "-"}}</div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 mt-3">
                                <div class="header" style="font-size: 11px;">Accepted Inputs</div>
                                <div class="value-1">String</div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                        <div class="row">
                            <div class="col-md-10 mt-3">
                                <div class="header" style="font-size: 11px;">Outputs Columns</div>
                                <div class="value-1">{{app.datasource_input_columns ? app.datasource_input_columns : "-"}}</div>
                            </div>
                            <div class="col-md-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="buildbutton"  mat-dialog-actions>
        <div></div>
        <button class="flatbutton" mat-flat-button color="primary" (click)="Done()">Done</button>
    </div>
</div>